import React from "react";
import {
  StyleSheet,
  View,
  TouchableWithoutFeedback,
} from "react-native";
import Modal from "react-native-modal";

const ModalProp = ({
  children,
  open,
  backdropPress,
  ...props
}) => {

  const styles = StyleSheet.create({
    container: {
      flex: 1,
      justifyContent: "center",
      alignItems: "center"
    }
  });

  const localDialogPress = (e) => {
    if (backdropPress) {
      backdropPress(e);
    }
  }

  return (
    <Modal
      style={styles.container}
      isVisible={open}
      animationIn='fadeIn'
      animationOut='fadeOut'
      customBackdrop={
        <TouchableWithoutFeedback onPress={localDialogPress}>
          <View style={{ flex: 1, backgroundColor: 'rgba(0,0,0,0.5)' }} />
        </TouchableWithoutFeedback>
      }
    >
      {children}
    </Modal>
  );
};

export default ModalProp;
