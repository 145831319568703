import React from 'react';
import { getDic } from '../assets/i18n/dictionary';
import {
  StyleSheet,
  View,
  TouchableOpacity,
  Text,
  TouchableWithoutFeedback
} from "react-native";
import Modal from "react-native-modal";
import { Entypo, FontAwesome } from '@expo/vector-icons';

const DialogProp = ({
  children,
  open,
  title = "",
  message = "",
  button1Text = "",
  button1OnPress,
  button2Text = "",
  button2OnPress,
  button3Text = "",
  button3OnPress,
  backdropPress,
  icon = 'info', // success, error, danger, info
  ...props
}) => {

  const styles = StyleSheet.create({
    container: {
      flex: 1,
      justifyContent: "center",
      alignItems: "center"
    },
    backdrop: {
      flex: 1,
      justifyContent: "center",
      alignItems: "center"
    },
    window: {
      backgroundColor: 'white',
      justifyContent: "center",
      alignItems: "center",
      borderRadius: 15,
      minHeight: 100,
      minWidth: 150,
      maxWidth: '80%',
      padding: 15,
      elevation: 20,
      shadowColor: '#52006A',
    },
    messageView: {
      alignItems: 'center',
      paddingBottom: 10
    },
    buttonView: {
      alignSelf: 'flex-end',
      right: 0,
      flexDirection: 'row-reverse'
    },
    button: {
      minWidth: 30,
      minHeight: 20,
      paddingLeft: 15,
      paddingRight: 15
    },
    buttonText: {
      fontFamily: 'Verdana',
      color: '#404040',
      fontSize: 16,
      fontWeight: 'bold'
    },
    textTitle: {
      fontFamily: 'Verdana',
      color: '#404040',
      fontSize: 18,
      fontWeight: 'bold',
      paddingBottom: 10
    },
    textMessage: {
      fontFamily: 'Verdana',
      color: '#404040',
    },
    icon: {
      marginTop: -40,
      backgroundColor: 'white',
      borderRadius: 50,
      width: 62,
      alignItems: 'center',
    }
  });

  const localDialogPress = (e) => {
    if (backdropPress) {
      backdropPress(e);
    }
  }

  return (
    <Modal
      style={styles.container}
      isVisible={open}
      animationIn='fadeIn'
      animationOut='fadeOut'
      customBackdrop={
        <TouchableWithoutFeedback onPress={localDialogPress}>
          <View style={{ flex: 1, backgroundColor: 'rgba(0,0,0,0.5)' }} />
        </TouchableWithoutFeedback>
      }
    >
      <View style={styles.backdrop}>
        <View style={styles.window}>
          <View style={styles.messageView}>
            {renderIcon()}
            {title !== '' && (
              <Text style={styles.textTitle}>{title}</Text>
            )}
            {message !== '' && (
              <Text style={styles.textMessage}>{message}</Text>
            )}
          </View>
          {children && (
            <View>
              {children}
            </View>
          )}
          {button1OnPress && (
            <View style={styles.buttonView}>
              {button1OnPress && (
                <TouchableOpacity style={styles.button} onPress={button1OnPress}>
                  <Text style={styles.buttonText}>{button1Text ? button1Text : getDic("sim")}</Text>
                </TouchableOpacity>
              )}
              {button2OnPress && (
                <TouchableOpacity style={styles.button} onPress={button2OnPress}>
                  <Text style={styles.buttonText}>{button2Text ? button2Text : getDic("nao")}</Text>
                </TouchableOpacity>
              )}
              {button3OnPress && (
                <TouchableOpacity style={styles.button} onPress={button3OnPress}>
                  <Text style={styles.buttonText}>{button3Text ? button3Text : getDic("cancelar")}</Text>
                </TouchableOpacity>
              )}
            </View>
          )}
        </View>
      </View>
    </Modal>
  )

  function renderIcon() {
    if (icon === 'success') {
      return (
        <View style={styles.icon}>
          <FontAwesome name="check-circle" size={60} color="green" />
        </View>
      )
    } else if (icon === 'error') {
      return (
        <View style={styles.icon}>
          <Entypo name="circle-with-cross" size={60} color="red" />
        </View>
      )
    } else if (icon === 'danger') {
      return (
        <View style={styles.icon}>
          <FontAwesome name="exclamation-circle" size={60} color="orange" />
        </View>
      )
    } else {
      return (
        <View style={styles.icon}>
          <Entypo name="info-with-circle" size={60} color="#0072A3" />
        </View>
      )
    }
  }
};

export default DialogProp;
