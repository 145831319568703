import React from "react";
import {
  StyleSheet,
  ActivityIndicator,
  View,
  Text,
} from "react-native";
import Modal from "react-native-modal";

const Loading = ({
  open,
  loadingMessage,
  ...props
}) => {

  const styles = StyleSheet.create({
    container: {
      flex: 1,
      justifyContent: "center",
      alignItems: "center"
    },
    backdrop: {
      flex: 1,
      justifyContent: "center",
      alignItems: "center"
    },
    window: {
      width: 150,
      height: 100,
      backgroundColor: 'white',
      justifyContent: "center",
      alignItems: "center",
      borderRadius: 15,
      elevation: 20,
      shadowColor: '#52006A',
    },
    text: {
      fontFamily: 'Verdana',
      color: '#404040'
    }
  });

  return (
    <Modal
      style={styles.container}
      isVisible={open}
      animationIn='fadeIn'
      animationOut='fadeOut'
      customBackdrop={
        <View style={{ flex: 1, backgroundColor: 'rgba(0,0,0,0.5)' }} />
      }
    >
      <View style={styles.backdrop}>
        <View style={styles.window}>
          <ActivityIndicator color={styles.text.color} size="large" />
          <Text style={styles.text}>{loadingMessage}</Text>
        </View>
      </View>
    </Modal>
  );
};

export default Loading;
