import UserModel from '../../models/UserModel';
import { SET_USER, DELETE_USER, CLEAR_USER } from '../actions';

const INITIAL_STATE = UserModel();

export default function userReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case SET_USER:
      return { ...state, ...action.payload };
    case DELETE_USER:
      return { ...state, ...INITIAL_STATE };
    case CLEAR_USER:
      return [];
    default:
      return state;
  }
}
