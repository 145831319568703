export const SET_USER = 'SET_USER';
export function setUser(payload) {
  return function (dispatch) {
    dispatch({
      type: SET_USER,
      payload,
    });
  };
}

export const DELETE_USER = 'DELETE_USER';
export function deleteUser() {
  return function (dispatch) {
    dispatch({
      type: DELETE_USER,
    });
  };
}

export const CLEAR_USER = 'CLEAR_USER';
export function clearUser() {
  return function (dispatch) {
    dispatch({
      type: CLEAR_USER,
    });
  };
}
