import React from 'react';
import {
  StyleSheet,
  View,
  ImageBackground,
} from 'react-native';

const Card = ({
  children,
  padding = 5,
  background = 'white',
  backgroundImage,
  borderRadius = 15,
  borderTopLeftRadius,
  borderBottomLeftRadius,
  borderTopRightRadius,
  borderBottomRightRadius,
  borderColor,
  borderWidth,
  borderLeftColor,
  borderRightColor,
  borderTopColor,
  borderBottomColor,
  borderLeftWidth,
  borderRightWidth,
  borderTopWidth,
  borderBottomWidth,
  width,
  height,
  resizeMode = 'cover',
  ...props
}) => {

  const styles = StyleSheet.create({
    container: {
      backgroundColor: backgroundImage ? 'transparent' : background || '#ffffff',
      borderTopLeftRadius: borderTopLeftRadius !== undefined ? borderTopLeftRadius : borderRadius,
      borderBottomLeftRadius: borderBottomLeftRadius !== undefined ? borderBottomLeftRadius : borderRadius,
      borderTopRightRadius: borderTopRightRadius !== undefined ? borderTopRightRadius : borderRadius,
      borderBottomRightRadius: borderBottomRightRadius !== undefined ? borderBottomRightRadius : borderRadius,
      padding: padding,
      shadowColor: "#000",
      shadowOffset: {
        width: 0,
        height: 2,
      },
      shadowOpacity: 0.25,
      shadowRadius: 3.84,
      elevation: 5,
      justifyContent: "center",
      borderLeftColor: borderLeftColor || borderColor,
      borderRightColor: borderRightColor || borderColor,
      borderTopColor: borderTopColor || borderColor,
      borderBottomColor: borderBottomColor || borderColor,
      borderLeftWidth: borderLeftWidth || borderWidth,
      borderRightWidth: borderRightWidth || borderWidth,
      borderTopWidth: borderTopWidth || borderWidth,
      borderBottomWidth: borderBottomWidth || borderWidth,
      width: width,
      height: height,
    },
    imageBackground: {
      borderRadius: borderRadius,
      flex: 1,
    },
  });

  if (backgroundImage) {
    return (
      <ImageBackground
        source={{ uri: backgroundImage }}
        style={styles.imageBackground}
        imageStyle={{ borderRadius: borderRadius, resizeMode: resizeMode }}
      >
        {renderCard()}
      </ImageBackground>
    )
  }
  return renderCard();

  function renderCard() {
    return (
      <View style={
        styles.container
      }>
        {children}
      </View>
    )
  }
};

export default Card;
